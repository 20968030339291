<template>
  <div>
    <div v-show="showSearch" class="main-top">
      <div class="check-box">
        <div class="check-box-item" v-for="item in checkBoxQueryParams">
          <div style="width: 70px">
            <el-checkbox
              v-if="item.name"
              @change="changeCheckBox"
              v-model="item.isCheck"
            >
              <div class="check-box-item-name">{{ item.name }}</div>
            </el-checkbox>
          </div>
          <div style="flex: 1" v-if="item.key == 'jb'">
            <div class="check-box-item-params">
              <div class="check-box-item-params-item">
                <div>分组选择：</div>
                <div style="flex: 1" class="form-border">
                  <SelectGroupTree
                    ref="groupTree"
                    :multipleProp="{ groupCheckBox: true }"
                    @on-confirm="onCheckGroup"
                    placeholder="请选择"
                  />
                </div>
              </div>
              <div class="check-box-item-params-item">
                <div>日期：</div>
                <div style="flex: 1">
                  <el-date-picker
                    style="width: 100%"
                    v-model="item.params.date"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
              </div>
            </div>
          </div>
          <div
            style="flex: 1; display: flex; flex-direction: column"
            v-else-if="item.key == 'bj'"
          >
            <div style="display: flex; flex-wrap: wrap; width: 100%">
              <div
                v-for="(pitem, pi) in item.params"
                style="width: 50%; margin-bottom: 8px; padding-left: 45px"
              >
                <div class="flex-item">
                  <el-select
                    style="margin-right: 4px"
                    v-model="pitem.alarmType"
                    placeholder="报警类型"
                  >
                    <el-option
                      v-for="item in alarmTypeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <el-select
                    style="margin-right: 4px"
                    v-model="pitem.ltOrGt"
                    placeholder="操作符"
                  >
                    <el-option
                      v-for="item in ltOrGtTypes"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <el-input
                    type="number"
                    style="margin-right: 4px"
                    v-model="pitem.data"
                    placeholder="请输入次数"
                  >
                    <div slot="append">次</div>
                  </el-input>
                  <div @click="deletAlarm(pi)" class="delet-btn">
                    <i class="el-icon-minus"></i>
                  </div>
                </div>
              </div>
            </div>
            <div @click="alarmAdd" class="check-box-item-params-item alarm-add">
              <div class="alarm-add-icon">
                <i class="el-icon-plus"></i>
              </div>
              <div class="alarm-add-text">报警指标</div>
            </div>
          </div>
          <div style="flex: 1" v-else>
            <div v-if="item.params" class="check-box-item-params">
              <div
                class="check-box-item-params-item"
                v-for="pitem in item.params"
              >
                <div>{{ pitem.title }}</div>
                <div style="flex: 1">
                  <el-input
                    type="number"
                    v-model="pitem.data"
                    placeholder="请输入"
                  >
                    <div
                      style="
                        width: 10px;
                        display: flex;
                        justify-content: center;
                      "
                      v-if="pitem.append"
                      slot="append"
                    >
                      {{ pitem.append }}
                    </div>
                  </el-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="top-right">
        <el-button
          @click="submit"
          icon="el-icon-search"
          class="base-btn"
          type="primary"
          >查询</el-button
        >
        <el-button
          @click="reset"
          icon="el-icon-refresh"
          class="base-btn"
          type="primary"
          >重置</el-button
        >
      </div>
    </div>

    <SimpleTable
      ref="table"
      :tableProps="tableProps"
      :queryFun="queryFun"
      @hiddenSearch="showSearch = !showSearch"
    >
      <div class="statistics" slot="table-top">
        <span v-for="item in totalKeys">{{ item.name }}{{ item.data }}; </span>
      </div>
      <template slot="right-r" slot-scope="{ data }"
        ><el-button
          icon="el-icon-upload2"
          class="export-btn"
          @click="exportData(data.params)"
          >导出</el-button
        ></template
      >
      <template slot="action" slot-scope="{ data }">
        <div style="color: #0080ff; cursor: pointer">
          <!-- <span
            @click="showMap = true"
            style="border-right: 1px solid #cccccc; padding-right: 4px"
            >查看定位</span
          > -->
          <span style="padding-left: 4px" @click="toVehicleDetails(data.index)"
            >车辆档案</span
          >
        </div>
      </template>
    </SimpleTable>
    <mapPositionDialog :show.sync="showMap"></mapPositionDialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import SimpleTable from "@/components/SimpleTable";
import SelectGroupTree from "@/components/GroupTree/NewSelectGroupTree";
import mapPositionDialog from "../components/mapPositionDialog";
import { getAlarmConf } from "@/api/vehicleApi";
import { exportMallCarAlarm, mallCarAlarmList } from "@/api/getData.js";
export default {
  name: "commercialVehicleStatisticalReport",
  components: { SimpleTable, SelectGroupTree, mapPositionDialog },
  created() {
    // 3 使下拉和表格对上
    getAlarmConf({ flag: 3 }).then((res) => {
      this.alarmTypeList = res.obj.map((item) => {
        return {
          ...item,
          key: item.alarmId,
          value: item.alarmId,
          label: item.alarmName,
        };
      });
    });
  },
  data() {
    return {
      showSearch: true,
      showMap: false,
      ltOrGtTypes: [
        { value: "1", label: "大于" },
        { value: "2", label: "小于" },
      ],
      totalKeys: [
        {
          name: "全部:",
          key: "total",
          data: 0,
        },
        {
          name: " FCW预警:",
          key: "FCWCount",
          data: 0,
        },
        {
          name: "AEB制动:",
          key: "AEBCount",
          data: 0,
        },
        {
          name: "起步阻止:",
          key: "SSSCount",
          data: 0,
        },
        {
          name: "LDW预警:",
          key: "LDWCount",
          data: 0,
        },
        {
          name: "HMW预警:",
          key: "HMWCount",
          data: 0,
        },
      ],
      alarmTypeList: [],
      checkBoxQueryParams: {
        lc: {
          isCheck: false,
          key: "lc",
          name: "里程",
          params: [
            {
              title: "里程大于：",
              append: "km",
              key: "mileageGT",
              data: "",
            },
            {
              title: "里程小于：",
              append: "km",
              key: "mileageLT",
              data: "",
            },
          ],
        },
        gc: {
          isCheck: false,
          key: "gc",
          name: "工时",
          params: [
            {
              title: "工时大于：",
              append: "h",
              key: "workGT",
              data: "",
            },
            {
              title: "工时小于：",
              append: "h",
              key: "workLT",
              data: "",
            },
          ],
        },
        cs: {
          isCheck: false,
          key: "cs",
          name: "车速",
          params: [
            {
              title: "车速大于：",
              append: "km/h",
              key: "speedGT",
              data: "",
            },
            {
              title: "车速小于：",
              append: "km/h",
              key: "speedLT",
              data: "",
            },
          ],
        },
        bj: {
          isCheck: false,
          key: "bj",
          name: "报警",
          params: [
            // {
            //   alarmType: "",
            //   //1大于，2小于
            //   ltOrGt: "",
            //   data: "",
            // },
            // {
            //   alarmType: "",
            //   //1大于，2小于
            //   ltOrGt: "",
            //   data: "",
            // },
          ],
        },
        jb: {
          isCheck: true,
          key: "jb",
          name: "",
          params: {
            date: [],
            dateRangeKeys: ["beginDate", "endDate"],
            groupIdList: [],
          },
        },
      },
      tableProps: {
        fuzzyQueryKey: "fuzzySearch",
        currentPageKey: "index",
        currentSizeKey: "size",
        totalKey: "total",
        isSearch: false,
        isFuzzyQuery: false,
        height: 360,
        innerPadding: "0 12px",
        columnProps: [
          {
            prop: "",
            label: "序号",
            width: "55",
            type: "index",
            isShow: true,
          },
          {
            prop: "groupName",
            label: "车组",
            width: "",
            type: "",
            isShow: true,
          },

          {
            prop: "plate",
            label: "车牌号",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "driverName",
            label: "驾驶员",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "LDWCount",
            label: "LDW次数",
            isShow: true,
            linkShowKey: "bj",
          },
          {
            prop: "AEBCount",
            label: "AEB次数",
            width: "",
            type: "",
            isShow: true,
            linkShowKey: "bj",
          },
          {
            prop: "FCWCount",
            label: "FCW次数",
            width: "",
            isShow: true,
            linkShowKey: "bj",
          },
          {
            prop: "HMWCount",
            label: "HMW次数",
            width: "",
            isShow: true,
            linkShowKey: "bj",
          },
          {
            prop: "SSSCount",
            label: "起步阻止次数",
            width: "",
            type: "",
            isShow: true,
            linkShowKey: "bj",
          },
          {
            prop: "CSCount",
            label: "超速次数",
            width: "",
            type: "",
            isShow: true,
            linkShowKey: "cs",
          },
          {
            prop: "mileage",
            label: "当日里程",
            width: "",
            type: "",
            isShow: true,
            linkShowKey: "lc",
          },
          {
            prop: "work",
            label: "当日工时",
            width: "",
            type: "",
            isShow: true,
            linkShowKey: "gc",
          },
          {
            prop: "date",
            label: "日期",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "action",
            label: "操作",
            width: "80",
            type: "",
            slot: true,
            isShow: true,
          },
        ],
      },
      vehicleList: [],
    };
  },
  methods: {
    setColumnProps() {
      this.tableProps.columnProps.forEach((item) => {
        if (item.linkShowKey) {
          item.isShow = this.checkBoxQueryParams[item.linkShowKey].isCheck;
        }
      });
    },
    changeCheckBox() {
      this.setColumnProps();
    },
    alarmAdd() {
      this.checkBoxQueryParams.bj.params.push({
        alarmType: "",
        //1大于，2小于
        ltOrGt: "",
        data: "",
      });
    },
    deletAlarm(index) {
      this.checkBoxQueryParams.bj.params.splice(index, 1);
    },
    onCheckGroup(data) {
      this.checkBoxQueryParams.jb.params.groupIdList = data.map(
        (item) => item.groupId
      );
    },
    exportData() {
      let { currentPage, currentSize } = this.$refs.table.getParams();
      exportMallCarAlarm({
        ...this.extractParameter(),
        index: currentPage,
        size: currentSize,
      });
    },
    extractParameter() {
      let res = {};
      for (const key in this.checkBoxQueryParams) {
        const obj = this.checkBoxQueryParams[key];
        if (obj.isCheck) {
          if (key == "bj") {
            res["SBAlarmList"] = this.handleAlarmParam(obj.params || []);
          } else if (key == "jb") {
            res["groupIdList"] = obj.params.groupIdList;
            res[obj.params.dateRangeKeys[0]] = obj?.params?.date[0]
              ? dayjs(obj.params.date[0]).format("YYYY-MM-DD")
              : "";
            res[obj.params.dateRangeKeys[1]] = obj?.params?.date[1]
              ? dayjs(obj.params.date[1]).format("YYYY-MM-DD")
              : "";
          } else {
            obj.params.forEach((item) => {
              if (item.data) {
                res[item.key] = item.data;
              }
            });
          }
        }
      }
      return res;
    },
    handleAlarmParam(list = []) {
      let res = [];
      list.forEach((item) => {
        if (item.alarmType && item.ltOrGt) {
          let ltgtkey = "";
          switch (item.ltOrGt) {
            case "1":
              ltgtkey = "alarmCountGT";
              break;
            case "2":
              ltgtkey = "alarmCountLT";
              break;
            default:
              break;
          }
          const indexkey = res.findIndex(
            (resitem) => resitem.alarmType == item.alarmType
          );
          if (indexkey == -1) {
            res.push({
              alarmType: item.alarmType,
              [ltgtkey]: item.data,
            });
          } else {
            res[indexkey] = {
              ...res[indexkey],
              [ltgtkey]: item.data,
            };
          }
        }
      });
      return res;
    },
    submit() {
      this.$refs.table.getData(true);
    },
    reset() {
      for (const key in this.checkBoxQueryParams) {
        const obj = this.checkBoxQueryParams[key];
        if (key == "bj") {
          obj.params = [];
        } else if (key == "jb") {
          this.$refs.groupTree[0].reset();
          obj.params.groupIdList = [];
          obj.params.date = [];
        } else {
          obj.params.forEach((item) => {
            item.data = "";
          });
        }
      }
    },
    toVehicleDetails(index) {
      sessionStorage.setItem(
        "vehicleIdList",
        JSON.stringify(this.vehicleList.map((val) => val.vehicleId))
      );
      this.$nextTick(() => {
        this.$router.push({
          path: "/SCDetails",
          query: {
            vehicleIndex: index,
          },
        });
      });
    },
    async queryFun({ index, size }) {
      this.vehicleList = [];
      let res = await mallCarAlarmList({
        ...this.extractParameter(),
        index,
        size,
      });
      this.vehicleList = res?.obj?.dataSummaryList || [];
      res?.obj?.dataSummaryCount &&
        this.totalKeys.forEach((item) => {
          item.data = res?.obj?.dataSummaryCount[item.key] || 0;
        });
      return {
        localData: this.vehicleList,
        total: res?.obj?.total || 0,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.main-top {
  display: flex;
  background-color: #ffffff;
  padding: 0 20px 20px 20px;
  box-sizing: border-box;
  margin-bottom: 10px;
  border-radius: 10px;
}
.top-right {
  display: flex;
  align-items: flex-end;
  margin-left: 24px;
}
.check-box {
  flex: 1;
  &-item {
    margin-top: 24px;
    display: flex;
    align-items: flex-start;
    flex: 1;
    &-params {
      display: flex;
      flex: 1;
      &-item {
        flex: 1;
        margin-left: 45px;
        display: flex;
        align-items: center;
      }
    }
    &-name {
      border-right: 1px solid #dee3e6;
      height: 32px;
      padding-right: 16px;
      display: flex;
      align-items: center;
    }
  }
}
.flex-item {
  display: flex;
  align-items: center;
}
.alarm-add {
  width: fit-content;
  height: fit-content;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #dee3e6;
  padding: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &-icon {
    width: 16px;
    height: 16px;
    background-color: #0080ff;
    color: #ffffff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
  &-text {
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    margin-left: 8px;
  }
}
.form-border {
  border: 1px solid #dee3e6;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 1px 0 1px 16px;
}
.statistics {
  font-weight: 400;
  font-size: 14px;
  color: #0080ff;
  margin: 0 12px 16px 12px;
}
.base-btn {
  background: #4278c9;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border-color: #4278c9;
}
.export-btn {
  background: #4278c9;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border-color: #4278c9;
}
.delet-btn {
  width: fit-content;
  height: fit-content;
  margin-left: 10px;
  background-color: red;
  color: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding: 8px;
  cursor: pointer;
}
</style>
