<template>
  <el-dialog title="最近位置" :visible.sync="dialogVisible">
    <div ref="container" class="map"></div>
  </el-dialog>
</template>

<script>
export default {
  name: "mapPositionDialog",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: false,
      data: { lon: 0, lat: 0 },
      mapall: null,
      marker: null,
    };
  },
  watch: {
    dialogVisible(val) {
      this.$emit("update:show", val);
    },
    show(newValue, oldValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.mapinit();
        });
      }
      this.dialogVisible = newValue;
    },
  },
  methods: {
    mapinit() {
      const that = this;
      let Lng = "";
      let Lat = "";
      // if (this.data.lonLat) {
      //   let lonLat = this.data.lonLat.split(",");
      //   Lng = lonLat[0];
      //   Lat = lonLat[1];
      // }
      Lng = this.data.lon;
      Lat = this.data.lat;
      if (that.mapall) {
        that.mapall.setCenter([Lng, Lat]);
        that.mapall.setZoom(15);
        that.mapall.remove(this.marker);
        // 创建一个 Marker 实例：
        this.marker = new AMap.Marker({
          position: new AMap.LngLat(Lng, Lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          title: this.data.alarmClassName,
          icon: "//yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/positioning-marker.png", // 添加 Icon 图标 URL
          offset: new AMap.Pixel(-20, -40),
        });

        // 将创建的点标记添加到已有的地图实例：
        that.mapall.add(this.marker);
      } else {
        var map = new AMap.Map(that.$refs.container, {
          resizeEnable: true,
          center: [Lng, Lat],
          zoom: 15,
        });
        that.mapall = map;
        // 创建一个 Marker 实例：
        this.marker = new AMap.Marker({
          position: new AMap.LngLat(Lng, Lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          title: this.data.alarmClassName,
          icon: "//yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/positioning-marker.png", // 添加 Icon 图标 URL
          offset: new AMap.Pixel(-20, -40),
        });

        // 将创建的点标记添加到已有的地图实例：
        that.mapall.add(this.marker);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.map {
  height: 202px;
  width: 100%;
  overflow: auto;
}
</style>
