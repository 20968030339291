<template>
  <div
    class="select-tree"
    :style="{height: height+ 'px', lineHeight: height+ 'px', width: '100%',}"
  >
        <el-popover placement="bottom" trigger="click" v-model="visible">
          <div class="tree-item" v-loading="isLoading" v-if="!isDestruction || visible">
            <div class="group-item">
              <GroupTree
                class="group-item_tree" isSearch :projectId="projectId"
                :showCheckbox="multipleProp.groupCheckBox"
                ref="groupTree"
                @checkChangeGroup="checkChangeGroup"
                :selecteds="selecteds" :isShowDisabledName="isShowDisabledName"
                :useDefault="true"
              />
            </div>
          </div>

          <!-- <div
            style="text-align: right; margin: 0"projectLoadGroups
            v-if="multipleProp.vehicleCheckBox ||multipleProp.groupCheckBox"
          >
            <el-button size="mini" type="text" @click="channelClick">取消</el-button>
            <el-button type="primary" size="mini" @click="submit">确定</el-button>
          </div> -->
          <div slot="reference" class="select-popover" ref="selectBox" @click="handleFocus" :style="{height: height+ 'px'}">
            <slot>
              <div class="select-popover-center" ref="selectBoxItem">
                <div v-for="(item,index) in (beyondIndex === null ? AllSelectList.length : beyondIndex)" class="select-popover-item" :key="index"> 
                  <div :style="{ maxWidth: firstWidth && index == 0 ? firstWidth + 'px' : '' }">{{ AllSelectList[index].groupName }}</div>
                  <i class="el-icon-close" @click="handleDelGroup(AllSelectList[index])"></i></div>
                <div v-if="beyondIndex !== null" class="select-popover-item"> +{{ AllSelectList.length - beyondIndex }}...</div>
                <div style="color:#ccc;font-size:12px;" v-if="!AllSelectList || !AllSelectList.length">{{ placeholder }}</div>
              </div>
              <div class="select-popover-icon" @click="handleBlur">
                <i class="el-icon-arrow-down" :style="{ display: !AllSelectList.length ? 'block' : '' }"></i>
                <i class="el-icon-circle-close" v-if="AllSelectList.length"></i>
              </div>
            </slot>
          </div>

        </el-popover>

    <!-- <div class="multiple-str" v-if="showTip()">
      <label
        class="tip-pane"
      >+{{`${this.selectVehicle.length + this.selectGroups.length || this.selecteds.length} `}}</label>
    </div>-->
  </div>
</template>

<script>
import GroupTree from '@/components/GroupTree/projectGroupTree';
import VirtualTree from '@/components/VirtualTree';
import VehicleAvator from './VehicleAvator';
import { getVehiclesByGroupId, searchMsg } from '@/api/getManagementData';
import { arrayToTree } from '@/utils/treeHelper.js';
import { stringify } from 'qs';

export default {
  name: 'SelectGroupTree',
  components: {
    GroupTree,
    VirtualTree,
    VehicleAvator,
  },
  props: {
    limit: { // 限制选择车辆数
      type: Number,
      default: -1
    },
    singleGroup: {//是否只展示组织
      type: Boolean,
      default: false
    },
    multipleProp: { //多选模式参数
      type: Object,
      default() {
        return { // 车组多选和车辆多选不能同时为true 同时设置了优先显示车组
          noGroup: false, // 是否可选车组
          groupCheckBox: false, //车组多选
          vehicleCheckBox: false, //车辆多选
        };
      },
    },
    disabled: { // 禁用状态
      type: Boolean,
      default: false
    },
    normal: { // 0 2 3 4 5 状态可选
      type: Boolean,
      default: false
    },
    alone: {
      type: String,
      default: ''
    },
    /**
     * 输入框是否空清空
     */
    clearable: {
      type: Boolean,
      default: true,
    },
    /**
     * 是否默认勾选车辆
     */
    defaultCheck: {
      type: Boolean,
      default: true,
    },
    size: { // input框size
      type: String,
      default: 'small'
    },

    selecteds: {//已选择的数据 用于修改功能 仅多选可用
      type: Array,
      default() {
        return []; // { label:车牌/组织, id:车辆ID/车组ID }
      }
    },
    inputVehicle: { // 输入查询车辆
      type: Boolean,
      default: true,
    },
    inputGroup: { // 输入查询车组
      type: Boolean,
      default: true,
    },
    inputDriver: { // 输入查询驾驶员
      type: Boolean,
      default: false,
    },

    placeholder: {
      type: String,
      default: '请选择'//终端号
    },
    height: {
      type: Number,
      default: 28
    },
    isDestruction: { // 隐藏时销毁
      type: Boolean,
      default: false,
    },
    projectId:  {
      type: [String, Number],
      default: ''
    },
    isShowDisabledName: { // 禁用 + 回显名称
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      vehicleList: [], // 车辆数据
      selectGroups: [], //已选择的车组
      selectVehicle: [], //已选的车辆
      emptyText: '--',
      input: '',
      visible: false,
      selectCount: 0,
      disabledCheck: true,
      colorData: {
        0: 'warning',
        1: 'success',
        2: 'success',
        3: '',
        4: 'danger',
        5: 'info',
        6: 'info',
        7: 'info',
      },
      isLoading: false,
      AllSelectList: [],
      beyondIndex: null,
      firstWidth: ''
    };
  },
  mounted() {
    this.setSelecteds(this.selecteds);
  },

  watch: {
    selecteds: {
      handler(val) {
        if (val.length) {
          this.setSelecteds(this.selecteds);
        }
      }
    },

    selectGroups: {
      handler(val, oldVal) {
        if (!val || !val.length) {
          this.AllSelectList = [];
          this.beyondIndex = null;
        } else {
          const parentIds = val.map(v=> v.groupId);
          this.AllSelectList = val.filter(v=> !parentIds.includes(v.parentId));
          const valIds = val.map(item => item.groupId);
          const oldValIds = oldVal.filter(item => !valIds.includes(item.groupId)).map(item2 => item2.groupId);
          this.selectVehicle = this.selectVehicle.filter(item => !oldValIds.includes(item.groupId));
        }
        // const data = val.map(item=> item.groupName).toString();
        // if(this.alone != 'vehicle') this.setInputStr(data);
      }
    },

    AllSelectList: {
      handler(val) {
        let childrenWidth = 0;
        this.beyondIndex = null;
        this.$nextTick(()=>{
            const boxWidth = this.$refs.selectBox.clientWidth - (val.length > 1 ? 67 : 35);
            this.firstWidth = '';
            for (let index = 0; index < (this.$refs?.selectBoxItem?.childNodes?.length || 0); index++) {
              childrenWidth += this.$refs.selectBoxItem.childNodes[index].clientWidth + 6;
              if(boxWidth < childrenWidth && this.beyondIndex === null) {
                if (index == 0 || index == 1) {
                  this.firstWidth = boxWidth - 38;
                  if (index == 1) {
                    this.beyondIndex = index;
                  }
                }else {
                  this.beyondIndex = index;
                }
              }
            }
        })
      },
      deep: true
    },
  },
  computed: {
    // 车辆树
    vehicleData() {
      return arrayToTree(this.vehicleList, { id: 'vehicleId' });
    },
    searchStr: {
      get: function () {
        return this.input || '';
      },
      set: function (value) {
        this.setInputStr(value);
      }
    },

  },
  methods: {

    itemClick(item) {
    },
    // 设置已选数据
    setSelecteds(list) {
      const labels = list.map(p => p.label);
      this.setInputStr(labels[0]);
    },
    showTip() {
      const { groupCheckBox, vehicleCheckBox } = this.multipleProp;
      return groupCheckBox || vehicleCheckBox;
    },

    // 点击取消
    channelClick() {
      // this.visible = false;
      this.$emit('on-channel');
      this.reset();
    },
    //组织树节点点击事件
    groupNodeClick(node, checked) {
      this.currentGroup = node;
      if (this.singleGroup) {
        // this.visible = false;
        this.setInputStr(node.groupName);
      }
      if (!this.multipleProp.groupCheckBox && this.singleGroup) return this.$emit('select-group', node);

    },

    //组织树双击事件
    dbGroupClick(node) {
      if (!this.multipleProp.noGroup) return;
      // this.visible = false;
      const text = node.groupName;
      this.$emit('dbClick', node);

      this.setInputStr(text);
    },

    //重置
    reset() {
      this.selectVehicle = [];
      this.selectGroups = [];
      this.$refs.groupTree.cancelAll();
      // 解决搜索后清空再次搜索无效问题
      // this.$refs.inlineInput.handleFocus();
      // document.activeElement.blur()

      this.checkedFilter(false);
    },

    // 判断是否可选
    handleFilterCar(item) {
      let flag = false;
      //  || ((this.limit !== -1 && this.selectVehicle.length >= this.limit) && !this.selectVehicle.filter(val => val.vehicleId == item.vehicleId).length)

      if ((this.normal && (item.vehicleStatus > 5 || item.vehicleStatus == 1))) {
        flag = true;
      }
      return flag;
      // (this.normal && (item.vehicleStatus > 5 || item.vehicleStatus == 1)) && (this.limit !== -1 && this.selectVehicle.length >= this.limit)
    },

    // 组织复选框勾选事件
    async groupCheckClick(node, checked) {
      // console.log(this.multipleProp.groupCheckBox);
      if (!this.multipleProp.groupCheckBox) {
        checked = true;
      }
      if (!node) return;
      this.isIndeterminate = true;
      if (checked) {
        if (!this.multipleProp.noGroup) {
          this.checkChangeGroup([node]);
        }
        this.$emit('select-group', node);
        // (( ? '' : (this.multipleProp.groupCheckBox ? this.selectGroups.push(node) : this.selectGroups = [node])),this.$emit('select-group', node));
        return false;
      }
      if (!checked) {
        if (this.multipleProp.vehicleCheckBox) {
          this.selectVehicle = this.selectVehicle.filter(item => item.groupId !== node.groupId && item.groupId !== node.parentId);
        }

        // this.selectGroups = this.selectGroups.filter(item => {
        //   if (item.groupId !== node.groupId && item.groupId !== node.parentId) {
        //     return true;
        //   } else if (item.groupId == node.parentId) {
        //     // console.log('半选');
        //     return false;
        //   }
        //   return false;
        // });
      };
    },
    // 选中车组数组
    async checkChangeGroup(data, checked) {
      // if (this.multipleProp.groupCheckBox) {
      this.$emit('on-confirm', data);
      if (data && data.length) {
        this.selectGroups = data;
      } else {
        this.selectGroups = [];
      }
    },

    //  多选确定点击事件
    submit() {
      // this.visible = false;
      const groupList = this.selectGroups;
      const vehicleList = this.selectVehicle;
      this.$emit('on-confirm', { groupList, vehicleList });
    },

    // 车辆树节点点击事件
    onNodeClick(node) {
      if (!this.multipleProp.vehicleCheckBox) {
        // this.visible = false;
        if (this.normal && (node.vehicleStatus > 5 || node.vehicleStatus == 1)) {
          this.$message.error('该车辆不能查询');
          return false;
        }
        this.$emit('on-vehicle', node);
        this.setInputStr(node.plate);
      }
    },
    // 车辆树节点勾选事件
    onCheckChange(node, checked) {
      // this.setTreeNodeDisable(this.selectVehicle, false);
      if (!checked) {
        this.selectVehicle = this.selectVehicle.filter(item => item.vehicleId !== node.vehicleId);
      } else if (!this.multipleProp.vehicleCheckBox) {
        this.selectVehicle = [node];
      } else {
        //判断最大可选车辆
        if (this.limit !== -1 && this.selectVehicle.length >= this.limit) {
          this.$refs.virtualTreeRef.setChecked(node, false);

          return this.$message.warning(`最多只可选择${ this.limit }台设备！`);
        }

        this.selectVehicle.push(node);
      }

      this.$emit('on-check', this.selectVehicle);

      // if (this.selectVehicle.length === this.limit) return this.setTreeNodeDisable(this.selectVehicle, true); // 勾选框可选状态
    },
    checkedFilter(checked) {
      if (checked) {
        this.vehicleList = this.vehicleList.map(val => {
          if (!(this.normal && (val.vehicleStatus > 5 || val.vehicleStatus == 1)) && this.multipleProp.vehicleCheckBox && (this.limit !== -1 ? this.selectVehicle.length >= this.limit : true)) {
            this.selectVehicle.push(val);
            val.checked = true;
          } else {
            val.checked = false;
          }
          return { ...val };
        });
      } else {
        this.vehicleList = this.vehicleList.map(val => ({ ...val, checked: false }));
      }
    },

    // 设置可选框状态
    setTreeNodeDisable(selects = [], disabled = false) {
      this.$refs.virtualTreeRef.getAllNodes().forEach(node => {
        if (!selects.map(p => p.vehicleId).includes(node.vehicleId)) {
          this.$refs.virtualTreeRef.setDisabledNode(node.vehicleId, disabled);
        }
      });
    },

    //清除选择
    onClear() {
      this.reset();
      this.$emit('clear');
    },

    open() {
      // this.vehicleList = [];
    },

    async querySearchAsync(queryString, cb) {
      const result = await searchMsg({ param: queryString });
      if (result.flag !== 1) return cb([]);
      const { groupNameList, plateList, driverNameList } = result.obj;
      let groupList = groupNameList ? groupNameList.map(item => ({ ...item, type: 1 })) : []; //车组类型数据
      let vehicleList = plateList ? plateList.map(item => ({ ...item, type: 2 })) : [];//车辆类型数据
      let driverList = driverNameList ? driverNameList.map(item => ({ ...item, type: 3 })) : []; // 驾驶员
      if (!this.inputVehicle) vehicleList = [];
      if (!this.inputGroup) groupList = [];
      if (!this.inputDriver) driverList = [];

      const params = await [...groupList, ...vehicleList, ...driverList];
      cb(params);
      this.$forceUpdate();
    },

    //输入建议选择事件
    onSelect(item) {
      let text = !item.plate ? item.groupName : item.plate;
      if (item.driverName) text = item.driverName;
      this.setInputStr(text);
      this.selectVehicle.length ? this.selectVehicle = [] : '';
      // if (!this.multipleProp.vehicleCheckBox) { // 多选的情况已经触发了勾选事件不需要重复push
      //   this.selectVehicle.push(item);
      // }
      if (item.type == 1) {
        // this.selectGroups.push(item);
        this.$refs.groupTree.handleSelect(item);
      } else {
        //  if (!this.selectVehicle.some(val=> val.vehicleId == item.vehicleId)) 
        // this.selectVehicle.push(item);
        this.selectVehicle = [item];
      }

      this.$emit('on-select', item);
    },

    setInputStr(item) {
      this.input = item;
    },
    handleBlur(event) {
      if (this.AllSelectList.length) {
        event.stopPropagation();
        this.$refs.groupTree.cancelAll();
      }
    },
    handleFocus() {
      // this.visible = true;
    },
    handleDelGroup(item) {
      this.$refs.groupTree.setCheckedKey(item.groupId, false)
    }
  }
}
</script>

<style lang="scss" scoped>
.select-popover {
  width: 100%;
  // height: 52px;
  display: flex;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  &-center {
    display: flex;
    align-items: center;
    // gap: 4px;
    flex: 1;
  }
  &-icon {
    width: 35px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .el-icon-circle-close {
      display: none;
    }
  }
  &-icon:hover {
    .el-icon-arrow-down {
      display: none;
    }
    .el-icon-circle-close {
      display: block;
    }
  }
  &-item {
    display: flex;
    align-items: center;
    white-space: nowrap;
    height: 28px;
    font-size: 12px;
    color: #333333;
    padding: 0 4px;
    margin-left: 6px;
    background: #F5F5F5;
    border-radius: 4px;
    div {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      height: 28px;
      line-height: 28px;
    }
    .el-icon-close {
      color: #000;
      font-weight: 600;
      margin-left: 6px;
      cursor: pointer;
    }
  }
}
.select-tree {
  position: relative;
  display: flex;
  align-items: center;
  >span {
    width: 100%;
  }
}
.tree-item {
  // width: auto;
  width: 400px;
  height: 350px;
  display: flex;
}
.group-item {
  position: relative;
  // width: 180px;
  width: 400px;
  padding-right: 10px;
}
.group-item_tree {
  height: calc(100% - 21px);
}
.vehicle-item {
  width: 280px;
  border-left: 1px #ddd solid;
  padding-left: 15px;
}

.group-node {
  position: relative;
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 8px;
  & > span {
    width: 100px;
    height: auto;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.el-divider--horizontal {
  margin: 10px 0;
}
// .multiple-str {
//   position: absolute;
//   right: 40px;
//   top: 0px;
//   bottom: 1px;
//   border-bottom: 1px solid #DCDFE6;
//   border-top: 1px solid #DCDFE6;
.tip-pane {
  display: inline-block;
  // width: 20px;
  // height: 20px;
  // line-height: 34px;
  // border-radius: 50%;
  color: #66b1ff;
  // background: rgba(102, 177, 255, 0.2);
  padding: 0px 6px;
  text-align: center;
  font-size: 12px;
  line-height: 22px;
}
// }
.vehicle-node {
  & > span {
    width: 100%;
    height: auto;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.inline-input {
  width: 90%;
}

::v-deep .el-input__inner {
  box-sizing: border-box;
  border-radius: 4px 0 0 4px;
  //   border-right: none;
  // height: 32px;
}

::v-deep .el-button--small {
  border-radius: 0 4px 4px 0;
  border-left: none;
}
</style>

<style lang="scss">
.select-option {
  width: auto !important;
}
</style>
